<template>
    <div class="form-input-checkbox-group">
        <slot />
    </div>
</template>

<style lang="scss">
.form-input-checkbox-group {
    @include checkbox-group;
}
</style>
