<script setup>
const props = defineProps({
    config: {
        type: Object,
        default: () => {
        }
    }
});

//  Creates a form field with its validation state, you will use this inside your custom input components.
const { value, errorMessage, meta, resetField } = useField(() =>
    props.config?.name, props.config?.validationRules
);

// Show error + success classes in case fields have validation rules
const _requiresValidation = ref(props.config?.validationRules);
const requiresValidation = computed(() => _requiresValidation.value !== undefined);

const formFieldClass = computed(() => {
    return {
        'form-error': !meta.valid && meta.dirty && requiresValidation.value,
        'form-success': meta.valid && meta.dirty && requiresValidation.value,
        'form-input-smart-checkbox-wrapper': props.config?.smart,
        'form-input-checkbox-wrapper': !props.config?.smart
    }
})

// Change initial value with dynamic data of input field
resetField({
    value: props.config?.val ? props.config?.val : []
});

// Use watcher to update data with dynamic values (seems to be necessary...?)
watch(() => props.config?.val, () => {
    resetField({
        value: props.config?.val ? props.config?.val : []
    });

    _requiresValidation.value = props.config?.validationRules
});
</script>)

<template>
    <div :class="formFieldClass">
        <input
            :id="config.id"
            v-model="value"
            class="form-field-checkbox"
            :type="config.fieldType"
            :required="config.required"
            :checked="config.checked"
            :name="config.name"
            :value="config.value"
        >
        <label class="form-field-checkbox-label-wrapper" :for="config.id">
            <span class="form-field-checkbox-label" :class="config.required">
                {{ config.label }}
            </span>
            <LoadingSpinner v-if="config.pending" class="form-loader smart-checkbox-form-loader" />
        </label>

        <FormFieldMessage
            :msg-config="{
                errorMessage:errorMessage,
                error:errorMessage && meta.dirty
            }"
        />
    </div>
</template>

<style lang="scss">
.form-field-checkbox {
    @include form-field-checkbox;
}

.form-field-checkbox-label {
    @include form-field-checkbox-label;
}

.form-field-checkbox-label-wrapper {
    @include form-field-checkbox-label-wrapper;
}

.form-input-smart-checkbox-wrapper {
    @include form-input-smart-checkbox-wrapper;
}

.form-input-checkbox-wrapper {
    @include checkbox-wrapper;
}
</style>
